import React from "react"
import { Box, Flex, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import WufooForm from "react-wufoo-embed"

import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import {
  SectionHeading,
  VideoPlayer,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  PatientsStories,
  ServicesTreatmentsPagesHero,
  Seo,
  SidebarLinks,
  SpecialistsDirService,
} from "../../../components/elements"

const CancerTreat = ({ data, pageContext }) => {
  const page = data.datoCmsCancerTreat
  const heroimg = data.datoCmsAsset.gatsbyImageData

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title || page.title}
        description={page.seo.description || " "}
        image={page.seo.image || " "}
      />

      <ServicesTreatmentsPagesHero
        page={page}
        heroimg={heroimg}
        eyebrow="Cancers We Treat:"
      >
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </ServicesTreatmentsPagesHero>

      <Container width="full">
        <Stack
          direction={["column", "column", "row"]}
          spacing={12}
          py={[9, 9, 12, 16]}
        >
          <Main width={["full", "full", "2 / 3"]}>
            {/* Content */}
            <Flex flexWrap="wrap">
              <Box width="full">
                {page?.video?.url && (
                  <Box mb={[9, 9, 12]}>
                    <VideoPlayer url={page.video.url} />
                  </Box>
                )}

                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.descriptionNode.childMarkdownRemark.html,
                  }}
                />

                {pageContext.slug === "lung-cancer" && (
                  <Box mt={12} mx={-1}>
                    <WufooForm
                      userName="wvuhealthcare"
                      formHash="zvsa5780j5rb59"
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          </Main>

          <Box
            as="aside"
            id="sidebar"
            bg="lightgray"
            width={["full", "full", 1 / 3]}
          >
            <Box p={[3, 3, 6]}>
              {page.findADocKeywordId && (
                <SpecialistsDirService id={page.findADocKeywordId} />
              )}
              <SidebarLinks page={page} />
            </Box>
          </Box>
        </Stack>
      </Container>

      {/* Patient Stories */}
      <Section id="stories" width="full">
        <Container>
          <Flex flexWrap="wrap">
            <Box width="full">
              <SectionHeading textAlign="center">
                Patient Stories
              </SectionHeading>
            </Box>

            <Box width="full">
              <PatientsStories />
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default CancerTreat

export const query = graphql`
  query cancersWeTreatPageQuery($slug: String!) {
    datoCmsCancerTreat(slug: { eq: $slug }) {
      id
      findADocKeywordId
      title
      descriptionNode {
        childMarkdownRemark {
          html
          id
        }
      }
      linksNode {
        childMarkdownRemark {
          html
        }
      }
      video {
        url
      }
      slug
      meta {
        status
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
    datoCmsAsset(id: { eq: "DatoCmsAsset-15668971" }) {
      id
      gatsbyImageData
    }
  }
`
